import { createUseThemeHook } from 'legos/theme';

declare module 'legos/theme' {
  interface BulkLegoTheme {
    inPageFlowLayout: InPageFlowLayoutTheme;
  }
}

export interface InPageFlowLayoutTheme {
  backgroundColor: string;
  titleColor: string;
  bottomBorderColor: string;
}

const [
  InPageFlowLayoutThemeProvider,
  useInPageFlowLayoutTheme
] = createUseThemeHook(
  'inPageFlowLayout',
  (global): InPageFlowLayoutTheme => ({
    backgroundColor: '#ffffff',
    titleColor: global.neutral.color800,
    bottomBorderColor: global.neutral.color300
  })
);

export { InPageFlowLayoutThemeProvider, useInPageFlowLayoutTheme };
