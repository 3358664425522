
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.StyledNavigationBar_aabd6ea046d9cd11{display:flex;justify-content:space-between;align-items:center;border-bottom:1px solid var(--bottomBorderColor_6258b104856fe967);min-height:48px;padding:10px 8px;background-color:var(--backgroundColor_6639888a9d89bdac)}.NavigationBarTitle_ff1c82d608dd222a{color:var(--titleColor_1c6b09258e517299);font-size:12px;font-weight:700;letter-spacing:1px;text-align:center;text-transform:uppercase}.NavLinkIcon_f0427a57972b7478{cursor:pointer}.BackIconLink_9255fcb4205cc46a,.CloseIconLink_8882e59ec606268e{flex:1;height:24px;display:flex;align-items:center;text-decoration:none}.CloseIconLink_8882e59ec606268e{justify-content:flex-end}.BackIconLink_9255fcb4205cc46a.empty_1ba0bb7fb154b158,.CloseIconLink_8882e59ec606268e.empty_1ba0bb7fb154b158{cursor:default}.DesktopBtnTxt_449fe38d8897d02d{display:none}@media (min-width:600px){.DesktopBtnTxt_449fe38d8897d02d{display:inline;cursor:pointer;padding-left:4px}}`;
styleInject(_css)

/** StyledNavigationBar Props */
export type StyledNavigationBarCCM = {
  /** StyledNavigationBar Component Custom Properties */
  '$bottomBorderColor': string;
  '$backgroundColor': string;

  /** StyledNavigationBar Modifier Flags */
  // No modifiers classes found
};
/** Base StyledNavigationBar component */
export const StyledNavigationBar: ComponentCreator<StyledNavigationBarCCM> = createComponentCreator({
  "name": "StyledNavigationBar",
  "base": "StyledNavigationBar_aabd6ea046d9cd11",
  "prop": {
    "$bottomBorderColor": "--bottomBorderColor_6258b104856fe967",
    "$backgroundColor": "--backgroundColor_6639888a9d89bdac"
  },
  "mod": {}
});


/** NavigationBarTitle Props */
export type NavigationBarTitleCCM = {
  /** NavigationBarTitle Component Custom Properties */
  '$titleColor': string;

  /** NavigationBarTitle Modifier Flags */
  // No modifiers classes found
};
/** Base NavigationBarTitle component */
export const NavigationBarTitle: ComponentCreator<NavigationBarTitleCCM> = createComponentCreator({
  "name": "NavigationBarTitle",
  "base": "NavigationBarTitle_ff1c82d608dd222a",
  "prop": {
    "$titleColor": "--titleColor_1c6b09258e517299"
  },
  "mod": {}
});


/** NavLinkIcon Props */
export type NavLinkIconCCM = {
  /** NavLinkIcon Component Custom Properties */
  // No custom properties found

  /** NavLinkIcon Modifier Flags */
  // No modifiers classes found
};
/** Base NavLinkIcon component */
export const NavLinkIcon: ComponentCreator<NavLinkIconCCM> = createComponentCreator({
  "name": "NavLinkIcon",
  "base": "NavLinkIcon_f0427a57972b7478",
  "prop": {},
  "mod": {}
});


/** BackIconLink Props */
export type BackIconLinkCCM = {
  /** BackIconLink Component Custom Properties */
  // No custom properties found

  /** BackIconLink Modifier Flags */
  '$empty'?: boolean;
};
/** Base BackIconLink component */
export const BackIconLink: ComponentCreator<BackIconLinkCCM> = createComponentCreator({
  "name": "BackIconLink",
  "base": "BackIconLink_9255fcb4205cc46a",
  "prop": {},
  "mod": {
    "$empty": "empty_1ba0bb7fb154b158"
  }
});


/** CloseIconLink Props */
export type CloseIconLinkCCM = {
  /** CloseIconLink Component Custom Properties */
  // No custom properties found

  /** CloseIconLink Modifier Flags */
  '$empty'?: boolean;
};
/** Base CloseIconLink component */
export const CloseIconLink: ComponentCreator<CloseIconLinkCCM> = createComponentCreator({
  "name": "CloseIconLink",
  "base": "CloseIconLink_8882e59ec606268e",
  "prop": {},
  "mod": {
    "$empty": "empty_1ba0bb7fb154b158"
  }
});


/** DesktopBtnTxt Props */
export type DesktopBtnTxtCCM = {
  /** DesktopBtnTxt Component Custom Properties */
  // No custom properties found

  /** DesktopBtnTxt Modifier Flags */
  // No modifiers classes found
};
/** Base DesktopBtnTxt component */
export const DesktopBtnTxt: ComponentCreator<DesktopBtnTxtCCM> = createComponentCreator({
  "name": "DesktopBtnTxt",
  "base": "DesktopBtnTxt_449fe38d8897d02d",
  "prop": {},
  "mod": {}
});

