
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.StyledInPageFlowLayout_cefbc4f6959322fb{width:100%;height:inherit;min-height:100%;display:flex;flex-direction:column;justify-content:stretch;overflow-y:auto}.NavBarContainer_9669be3c3fa2abba{position:-webkit-sticky;position:sticky;top:0;z-index:1}.BodyContainer_5dd4e57c98dac0e1{flex-grow:inherit}.BodyContainer_5dd4e57c98dac0e1.flexCol_413d834d51744254{display:flex;flex-direction:column;flex:1}.BodyContainer_5dd4e57c98dac0e1.flexRow_1b2e885ac4763d80{display:flex;flex-direction:row}.FooterContainer_5cad0aa3048002e9.sticky_5da672d24a17e714{bottom:0;position:-webkit-sticky;position:sticky}@media screen and (max-width:600px){.StyledInPageFlowLayout_cefbc4f6959322fb{overflow:unset;background-color:#fff}}`;
styleInject(_css)

/** StyledInPageFlowLayout Props */
export type StyledInPageFlowLayoutCCM = {
  /** StyledInPageFlowLayout Component Custom Properties */
  // No custom properties found

  /** StyledInPageFlowLayout Modifier Flags */
  // No modifiers classes found
};
/** Base StyledInPageFlowLayout component */
export const StyledInPageFlowLayout: ComponentCreator<StyledInPageFlowLayoutCCM> = createComponentCreator({
  "name": "StyledInPageFlowLayout",
  "base": "StyledInPageFlowLayout_cefbc4f6959322fb",
  "prop": {},
  "mod": {}
});


/** NavBarContainer Props */
export type NavBarContainerCCM = {
  /** NavBarContainer Component Custom Properties */
  // No custom properties found

  /** NavBarContainer Modifier Flags */
  // No modifiers classes found
};
/** Base NavBarContainer component */
export const NavBarContainer: ComponentCreator<NavBarContainerCCM> = createComponentCreator({
  "name": "NavBarContainer",
  "base": "NavBarContainer_9669be3c3fa2abba",
  "prop": {},
  "mod": {}
});


/** BodyContainer Props */
export type BodyContainerCCM = {
  /** BodyContainer Component Custom Properties */
  // No custom properties found

  /** BodyContainer Modifier Flags */
  '$flexCol'?: boolean;
  '$flexRow'?: boolean;
};
/** Base BodyContainer component */
export const BodyContainer: ComponentCreator<BodyContainerCCM> = createComponentCreator({
  "name": "BodyContainer",
  "base": "BodyContainer_5dd4e57c98dac0e1",
  "prop": {},
  "mod": {
    "$flexCol": "flexCol_413d834d51744254",
    "$flexRow": "flexRow_1b2e885ac4763d80"
  }
});


/** FooterContainer Props */
export type FooterContainerCCM = {
  /** FooterContainer Component Custom Properties */
  // No custom properties found

  /** FooterContainer Modifier Flags */
  '$sticky'?: boolean;
};
/** Base FooterContainer component */
export const FooterContainer: ComponentCreator<FooterContainerCCM> = createComponentCreator({
  "name": "FooterContainer",
  "base": "FooterContainer_5cad0aa3048002e9",
  "prop": {},
  "mod": {
    "$sticky": "sticky_5da672d24a17e714"
  }
});

