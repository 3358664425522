import { createElement, FC, ReactNode, useEffect } from 'react';
import { BackButtonHandler } from 'packages/back-button-handler/react';
import { NavigationBar, NavigationBarProps } from './navigation-bar';

import {
  StyledInPageFlowLayout,
  NavBarContainer,
  BodyContainer,
  FooterContainer
} from './in-page-flow-layout.ccm.css';

export interface InPageFlowLayoutProps {
  /**
   * Usually a string, shown centered within the Nav bar
   */
  title: NavigationBarProps['children'];

  /**
   * If specified, a left-justified icon wrapped in an anchor will be shown in the Nav bar
   */
  backAnchor?: NavigationBarProps['backAnchor'];

  /**
   * If specified, a right-justified icon wrapped in an anchor will be shown in the Nav bar
   */
  cancelAnchor?: NavigationBarProps['cancelAnchor'];

  /**
   * The body of the layout
   */
  children: ReactNode;

  /**
   * The footer of the layout. Will be shown at bottom of layout, unless the height of `children` pushes it down
   */
  footer?: ReactNode;

  /**
   * If true, sets the footer to always be shown at the bottom of the layout
   * @default false
   */
  stickyFooter?: boolean;

  /**
   * If set, the body will be a flex container with the flex-direction specified
   */
  flex?: 'row' | 'column';
}

export const InPageFlowLayout: FC<InPageFlowLayoutProps> = ({
  title,
  backAnchor,
  cancelAnchor,
  children,
  footer,
  stickyFooter,
  flex
}) => {
  // The fixed (sticky) header position gets messed up sometimes on iOS due to scrolling issues.
  // This forces a re-calculation when the we first get rendered.
  // This will *not* fix the issue where the `children` contents of this are changed (thus
  // changing scroll position and height) as opposed to the case where an in page flow takes the visual
  // place of a previous in page flow.
  // TODO: Better solution of the below hack https://jira.corp.netspend.com/browse/GROOT-416
  useEffect(() => {
    const root = document.documentElement;
    const display = root.style.display;
    root.scrollTop++;
    root.style.display = 'inline';
    root.style.display = display;
    root.scrollTop--;
  }, []);
  const inPageFlow = (
    <StyledInPageFlowLayout.div>
      <NavBarContainer.div>
        <NavigationBar backAnchor={backAnchor} cancelAnchor={cancelAnchor}>
          {title}
        </NavigationBar>
      </NavBarContainer.div>
      <BodyContainer.div
        $flexRow={flex === 'row' ? true : undefined}
        $flexCol={flex === 'column' ? true : undefined}
      >
        {children}
      </BodyContainer.div>
      {footer && (
        <FooterContainer.div $sticky={stickyFooter}>
          {footer}
        </FooterContainer.div>
      )}
    </StyledInPageFlowLayout.div>
  );

  if (backAnchor) {
    return (
      <BackButtonHandler optional onBackButton={backAnchor.onClick}>
        {inPageFlow}
      </BackButtonHandler>
    );
  }

  return inPageFlow;
};
